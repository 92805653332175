<template>
  <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.70833 0L0 7.70833L7.70833 15.4167V0Z" fill="#F2F2F2" />
  </svg>
</template>

<script>
export default {
  name: 'PrevArrow',
};
</script>
