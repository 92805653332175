<template>
  <div class="container programs__filter filter">
    <div class="filter__main">
      <div
        v-for="(value, key) in filter"
        :key="key"
        class="filter__item"
        :class="{ 'filter__item--inactive': !value }"
        @click.prevent="selectFilter(key)"
      >
        <RadioButton
          :checked="value"
          :isTypeCheckBox="true"
          size="sm"
          @change="selectFilter(key)"
        />
        <p>{{ label(key) }}</p>
      </div>
    </div>
    <div class="filter__info-text" v-click-outside="closeFilterHint">
      <InfoIcon @click="toggleFilterHint" />
      <div class="filter__text-helper">
        <div class="text-helper" :class="{ 'text-helper--active': filterHint }">
          <div class="text-helper__item">
            <div class="text-helper__title">
              <p>{{ $t('label.author') }}</p>
            </div>
            <div class="text-helper__subtitle">
              <p>{{ $t('supportText.canCreateEditProgram') }}</p>
            </div>
          </div>
          <div class="text-helper__item">
            <div class="text-helper__title">
              <p>{{ $t('label.admin') }}</p>
            </div>
            <div class="text-helper__subtitle">
              <p>{{ $t('supportText.canEditProgramReviewMaterialsGransAccess') }}</p>
            </div>
          </div>
          <!-- <div class="text-helper__item">
            <div class="text-helper__title">
              <p>{{ $t('label.inspector') }}</p>
            </div>
            <div class="text-helper__subtitle">
              <p>{{ $t('supportText.canViewMaterialsCheckAndEvaluate') }}</p>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RadioButton from '@/components/Buttons/RadioButton.vue';
import InfoIcon from '@/components/Icons/InfoIcon.vue';

export default {
  name: 'ProgramsFilter',
  components: {
    RadioButton,
    InfoIcon,
  },
  props: {
    selectFilter: {
      type: Function,
      required: true,
    },
    filter: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    filterHint: false,
  }),
  directives: {
    'click-outside': {
      bind(el, binding, vnode) {
        // eslint-disable-next-line no-param-reassign
        el.clickOutsideEvent = (event) => {
          if (!(el === event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
      },
      unbind(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
      },
    },
  },
  methods: {
    label(key) {
      if (key === 'manager') {
        return this.$t('label.admin');
      }
      return this.$t(`label.${key}`);
    },
    toggleFilterHint() {
      this.filterHint = !this.filterHint;
    },
    closeFilterHint() {
      this.filterHint = false;
    },
  },
};
</script>
