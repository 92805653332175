<template>
  <b-button
    :variant="variant"
    class="btn-with-icon"
    @click="$emit('click')"
    :class="{ 'soft-disabled': isDisabled, small: size === 'small' }"
  >
    <svg
      v-if="size === 'small'"
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14 9H8V15H6V9H0V7H6V1H8V7H14V9Z"
        fill="black"
      />
    </svg>
    <svg
      v-else-if="addFile"
      width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.507 6.44587L7.14617 11.8067C6.48942
      12.4634 5.59869 12.8324 4.66992 12.8324C3.74114
      12.8324 2.85041 12.4634 2.19367 11.8067C1.53692
      11.15 1.16797 10.2592 1.16797 9.33045C1.16797
      8.40168 1.53692 7.51094 2.19367 6.8542L7.5545
      1.49337C7.99233 1.05554 8.58615 0.80957 9.20533
      0.80957C9.82451 0.80957 10.4183 1.05554 10.8562
      1.49337C11.294 1.9312 11.54 2.52502 11.54 3.1442C11.54
      3.76338 11.294 4.35721 10.8562 4.79503L5.4895 10.1559C5.27058
      10.3748 4.97367 10.4978 4.66408 10.4978C4.35449 10.4978 4.05758
      10.3748 3.83867 10.1559C3.61975 9.93695 3.49677 9.64004 3.49677
      9.33045C3.49677 9.02086 3.61975 8.72395 3.83867 8.50503L8.79116 3.55837"
      stroke="#FAA433"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
    <svg
      v-else
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19 10.8571H10.8571V19H8.14286V10.8571H0V8.14286H8.14286V0H10.8571V8.14286H19V10.8571Z"
        :fill="[variant === 'primary' ? 'black' : '#FAA433']"
      />
    </svg>
    <span class="mr-1">{{ title }}</span>
  </b-button>
</template>

<script>
export default {
  name: 'AddButtonWithIcon',
  props: {
    title: String,
    variant: {
      type: String,
      default: 'primary',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'default',
    },
    addFile: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
