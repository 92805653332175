<template>
  <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.041626 15.4167L7.74996 7.70833L0.041626 0V15.4167Z" fill="#F2F2F2" />
  </svg>
</template>

<script>
export default {
  name: 'NextArrow',
};
</script>
